import React, { useEffect, useState, useContext } from 'react';
import { useParams} from "react-router-dom";

import GraphQlApi from '../mobile/MyGraphql';

import { makeStyles } from '@material-ui/core/styles';

import moment from 'moment';

import Grid from '@material-ui/core/Grid';
 
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ArrowBack';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import Checkbox from '@material-ui/core/Checkbox';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import { getProbeDetail, isEmptyObject, lastObject, round } from '../Common';
import ChartMonoAxe from '../components/ChartMonoAxe'
import MyDateRangePicker from '../components/MyDateRangePicker'
import { CSVLink } from "react-csv";

import chartConfig from '../chart-config'; // configuration générale du graphique (noms possibles des axes Y (droite/gauche) couleurs)
import GeneralContext from '../components/GeneralContext';

import { toSnack } from '../Common';

const QUERY_MEASURES_LIMIT = 1000;

const DAYS_FOR_AGGREG_DATA = 6 /* days */;

const useStyles = makeStyles(theme => ({  
  appBarParam: {
    position: 'relative',
  },
  titleParam: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  alignItemsAndJustifyContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },  
  csvButton: {
    //height: 48,
    margin: "0 auto",
    width: "180px;"
  },    
  cvsLine: {
    margin: "10"
  },
  limitWarning: {
    backgroundColor: "red"
  },
  yAxisRadioGroup: {
    marginTop: 20,
    marginLeft: 30
  },
  bottomOptions: {
    marginLeft: 30,
    marginRight:50
  },
  statsHeader: {
    fontWeight: "bold"
  }
})
)

export default function Probe(props) {
  const { onClose } = props;

    let { id: serviceId, probe: probeId } = useParams();
    const { user, customer } = useContext(GeneralContext);

    const [service, setService] = useState({});
    const [loading, setLoading] = useState(false);
    const [histoData, setHistoData] = useState();
    const [csvData, setCsvData] = useState([]);
    const [csvFileName, setCsvFileName] = useState("csvfile");

    const [histoRangeType, setHistoRangeType] = useState();
  //  const [defaultStartPeriodPicker, setDefaultStartPeriodPicker] = useState();

    const [defaultRangePicker, setDefaultRangePicker] = useState();
    

    const [histoDateRange, setHistoDateRange] = useState();

    const [periodStats, setPeriodStats] = useState();
    const [probeConfig, setProbeConfig] = useState();
    //const [sumOrAvg, setSumOrAvg] = useState("avg"); // sum or avg

    const [graphConfig, setGraphConfig] = useState({});

    const [displayWarningMeasuresLimit, setDisplayWarningMeasuresLimit] = useState(false);

    const [chartOptions, setChartOptions] = useState({ beginAtZero: true, sumOrAvg: 'avg', hourOrDay: 'hour'});    

    const [yAxisCheckbox, setYAxisCheckbox] = useState(true); 

    const [viewByHourOrDay, setViewByHourOrDay] = useState('hour'); // hour, day

    const [displayPeriod, setDisplayPeriod] = useState(); // nb de jour de la période

    const classes = useStyles();

    // get Service
    useEffect(() => {
      //moment.locale('fr');
        let ignore = false;
        async function fetchData(serviceId) {
            console.log("Service is loading for %s ...", serviceId);
            setLoading(true);
            const getServiceQuery = `query GetService($id: ID!) {
                getService(id: $id) {
                  id
                  name
                  device {
                    id
                    manufacturer
                    status
                    template {
                      id
                      probesDisplay
                    }         
                  }
                  probesConfig
                  customer {
                    id
                  }
                  location
                  status
                }
              }`;
            //const result = await API.graphql(graphqlOperation(getServiceQuery, { id: serviceId }));
            const result = await GraphQlApi.getInstance().graphqlOperation( getServiceQuery,  { id: serviceId });
            setLoading(false);
            if (result.data.getService) {
                const theService = result.data.getService
                console.log("service :", theService);
                if (!ignore) {
                    setService(theService);
                    const defaultPeriod = getDefaultPeriod(theService, probeId); // today / yesterday / last7days / last30days / custom
                    console.log("defaultPeriod:",defaultPeriod);
                    //setHistoRangeType(defaultPeriod);   
                    setDefaultRangePicker({ type: defaultPeriod})                 
                }
            } else {
                console.log("User non trouvé en base");
            }

        }      
        fetchData(serviceId);
        return () => { ignore = true; }

    }, [serviceId]);

    useEffect(() => {
      if (!isEmptyObject(service)) {
        setProbeConfig(getProbeDetail(service, probeId));
      }
    }, [service]);
    
    //Affichage d'un JOUR
    useEffect(() => {
      // async function getData(histoDateRange) {
      //   console.log("************* Affichage d'un JOUR:",histoDateRange.start)
      //   const rawData = await getHistoAggregateOneDay(serviceId, probeId, histoDateRange.start);
      //   console.log("rawDayStats:",rawData);
      //   if (rawData.allDay) {
      //     console.log(probeConfig)
      //     setPeriodStats(JSON.parse(rawData.allDay));
      //   } else {
      //     setPeriodStats(null);
      //   }
      // }
      if (histoDateRange && moment(histoDateRange.end).isSame(histoDateRange.start, 'day')) {
        console.log("************* Affichage d'un JOUR seul :",histoDateRange.start)
      } else {
        setPeriodStats();
      }
      if (histoDateRange) {
        console.log("histoDateRange start:",histoDateRange.start)
        console.log("histoDateRange end :",histoDateRange.end)
        const daysCount = moment(histoDateRange.end).diff(moment(histoDateRange.start), 'days')+1;
        setDisplayPeriod(daysCount);
      }
    }, [histoDateRange]);

      // Date Change
  // Get Data (and format to graph and csv)
  // option sum or avg (switch ecran)
  useEffect(() => {
    console.log("Histo date changed (or service):", histoDateRange);
    setDisplayWarningMeasuresLimit(false);
    async function getNewHisto(service, probeId,  start, end) {
      const serviceId = service.id;
      var data, csvData;

      const probeConfig = getProbeDetail(service, probeId);
      console.log("probeConfig:",probeConfig);

      // le moment de l'observation, est  il dans la fourchette horaire ?
      // pour les graph on/off (STATE_IN)
      const nowInsideRange = isInsideDateRange(new Date(), start, end);
      console.log("nowInsideRange:",nowInsideRange);

      // si dans le template, pour la probe, dans les info  de dislpay
      // la sonde est de type isSum (ex kWH de Enedis ou une valeur en index)
      // la valeur représente la somme pour l'intervalle
      // on affiche un type de graphique en histogramme
      // avec le libelle spécifique  du type ?
      const graphType = probeConfig.isSum?'bar':'line';

      //const graphType = sumOrAvg==='sum'?'bar':'line';
 
      // moy à l'heure ou valeur réelles
      setLoading(true);
      if (moment.duration(moment(end).diff(moment(start))).days()<DAYS_FOR_AGGREG_DATA || probeConfig.type==="STATE_IN") {
        // il y a peu de mesure, on prend les mesures réelles
        // si le probeType=ON/Off, il y a aussi peu de valeur et l'aggregation par la moyenne de l'heure n'a pas de sens
        console.log("DATASOURCE : measure brut (par evenement)");
        var rawData;
        try {
          rawData = await getHistoBetween(serviceId, probeId, start, end);
        } catch (error) {
          console.log("Error : ",error);
          toSnack("PrpbePage","Erreur : impossible de récupérer les données depuis le serveur")
          setLoading(false);
          return;
        }
        console.log("%c--> rawData by event from DB:","color:blue", rawData);
        
        data = dataForGraph(rawData, service, nowInsideRange, graphType);
        csvData = dataForCSV(rawData)

        let isStatsOnly, sumOrAvg, dataByDayOrHour;
        if (probeConfig.unit==='W/m2' | probeConfig.unit==='W') {
          // pourl'affichage des cumuls de la période, si c'est des W, on doit génerer des WattHeure
          // on prendra la somme des moy/heure, qui devient des wattHeures
          console.log("Unit=W, récupération des moy par heure")

          // pour les min, max, sum de la période,
          isStatsOnly = false;  // on souhaite les données par heure

          sumOrAvg = 'avg'; //on souhaite la moy / heure pour faire des Watt par 15min à multiplier par 4 pour avoir des Wh
          // Attention, on peut avoir 1 ou 4 valeurs pour l'heure (ex si pb de comm). en prenant la moy de l'heure, on s'en affranchie
          // autre piste à tester sumOrAvg = Sum // on souhaite la sum de heure ppuis diviser par le nombre de valeurs de l'heure pour avoir des Wh

          dataByDayOrHour = 'hour'; // 

        } else {
          // pour toutes les unit sauf Watt
          // pour les min, max, sum de la période,
          isStatsOnly = true;  // on a pas besoins des data, car on prends les par events
          // sumOrAvg ne sert à rien puisque uniquement dans le data aggrégées
          sumOrAvg = undefined;
          dataByDayOrHour = undefined; // on ne prends que les stats, pas les data
        }

        const rawDataOneProbe = await getHistoMinMaxSumAggregateBetween(serviceId, probeId, start, end, sumOrAvg, isStatsOnly, dataByDayOrHour);
        // rawData [{dateTime: '', value: xx},...]
        console.log("%c--> rawData by day (stats):","color:blue", rawDataOneProbe);
        if (rawDataOneProbe && rawDataOneProbe.stats) {
          // maj de PeriodStats avec eventuellement le caclul des WattHeures 

          if (rawDataOneProbe.values && rawDataOneProbe.values.length>0) {
            // on a du détail par heure,
            if (probeConfig.unit==='W/m2' | probeConfig.unit==='W') {
              // on cumul les moyennes par heure (des Wh) pour avoir les Wh du jour
              rawDataOneProbe.stats.sum = computeSumForAvg(rawDataOneProbe.values);
              console.log("Unit=W, caclul du cumul jour en Wh à partir des avg/heure:", rawDataOneProbe.stats);
            }
          }
          setPeriodStats(rawDataOneProbe.stats); // { min: x, max: y, sum: z }
        }

      } else {
        // il y a trop de mesure a afficher, 
        // le niveau de détail aggrégé à l'heure (moyenne des valeurs) est suffisant

        // pour les min, max, sum de la période,
        // on les recupère de l'appel de getHistoAggregateBetween

        // si la donnée est une somme (un cumul), (cf le template, probedisplay)
        // alors il faut aller chercher la somme de l'aggregation de l'heure
        // (pour les unit=watt, on prend bien les avg/heure pour en faire des Wh à cummuler)
        const sumOrAvg = probeConfig.isSum?'sum':'avg';
        console.log("DATASOURCE : measure agrégés à l'heure par jour (sumOrAvg:%s",sumOrAvg);

        const isStatsOnly = false; // on veux les data et donc aussi les stats qui viennent avec.
        const dataByDayOrHour = viewByHourOrDay;
        const rawDataOneProbe = await getHistoMinMaxSumAggregateBetween(serviceId, probeId, start, end, sumOrAvg, isStatsOnly, dataByDayOrHour);
        // rawData [{dateTime: '', value: xx},...]
        console.log("%c-----> rawData by day FROM DB (data + stats):","color:blue", rawDataOneProbe);
        if (rawDataOneProbe) {
          console.log("Préparation des data pour le graphique ...")
          data = dataForGraphOneProbe(rawDataOneProbe.values, service, probeId, nowInsideRange, graphType);
          console.log("-->Pour le graph:", data)

          console.log("Préparation des data pour l'export CSV ...")
          csvData = dataAggregateForCSV(rawDataOneProbe.values)
          setPeriodStats(rawDataOneProbe.stats); // { min: x, max: y, sum: z }
        }
      }
      setLoading(false);
      //data['gaphType] = ''

      setHistoData(data);
      //console.log("data for graph:",data);

      setCsvData(csvData);
      //console.log("data for csv:",csvData);

      setCsvFileName(generateFileName(service, probeId,histoDateRange))
    }
    if (histoDateRange && !isEmptyObject(service) ) {
      getNewHisto(service, probeId, histoDateRange.start, histoDateRange.end);
    }
  }, [histoDateRange, service, viewByHourOrDay])

    async function getHistoBetween(serviceId, probeId, startDate, endDate) {
      console.log("getHistoBetween");
      console.log("Start: ",startDate);
      console.log("End: ",endDate);
        var measures;

        const getMeasureBetween = `query getMeasureFromDay($id: ID!, $startDate: String, $endDate:String, $itemsLimit: Int) {
            listMeasures(id: $id, dateEvent: { between : [ $startDate, $endDate ]}
            limit: $itemsLimit) {
              items {
                dateEvent
                values
              }
            }
          }`;
        //const response = await API.graphql(graphqlOperation(getMeasureBetween, { "id": serviceId,"startDate": startDate, "endDate": endDate }));
        const response = await GraphQlApi.getInstance().graphqlOperation( getMeasureBetween,  { "id": serviceId,"startDate": startDate, "endDate": endDate, "itemsLimit": QUERY_MEASURES_LIMIT });
        console.log("response:",response)
        if (response.data && response.data.listMeasures) {
          measures = response.data.listMeasures.items;
        }
        if (response.errors) {
          console.log("Errors:",response.errors)
          throw "Error while getting measures from db"
        }
        console.log("data loaded size:",measures.length)

        if (measures.length === QUERY_MEASURES_LIMIT) {
          //toSnack("MeasuresLimit","Le nombre maximum de mesures est atteint. Il est possible que toutes les informations ne soient pas présentes ("+QUERY_MEASURES_LIMIT+")");
          setDisplayWarningMeasuresLimit(true);
        }
    
        //this.dataProbe1 = this.extractProbe(this.measures, probeName);
        return measures;
    }

    // get data dans les données aggrégées a lheure, stockées par jour, avec des stats par jours
    // c'est la même fonction pour avoir data+stats ou uniquement les stats journalières
    // sumOrAvg : si la probe représente des cumul dans un interval, on prend la sum dans l'aggragation horaire
    //            sinon, on prends la moyenne horaire
    //            si uniquement les stats, ça ne sert à rien
    async function getHistoMinMaxSumAggregateBetween(serviceId, probeId, startDate, endDate, sumOrAvg, isStatsOnly, dataByDayOrHour) {
      // 
      console.log("getHistoBetween");
      console.log("Start: ",startDate);
      console.log("End: ",endDate);
      console.log("isStatsOnly: ",isStatsOnly);

      var dataresponse = {
        values: [],
        stats: {}
      };

      const idKey = serviceId+"-"+probeId;
      const startDay = moment(startDate).format('YYYY-MM-DD');
      const endDay = moment(endDate).format('YYYY-MM-DD');
      const inputData = { "id": idKey,"startDay": startDay, "endDay": endDay, "itemsLimit": QUERY_MEASURES_LIMIT }

      console.log("inputData:",inputData);

      var getMeasureBetween;
      if (isStatsOnly) {
        // eachHour n'est pas nécessaire 
        getMeasureBetween = `query getMeasureFromDay($id: ID!, $startDay: String, $endDay:String, $itemsLimit: Int) {
          listMeasureDailyStatsLocals(id: $id, dayEvent: {between: [ $startDay, $endDay ]},
          sortDirection: ASC,
          limit: $itemsLimit) {
            items {
              id
              dayEvent
              allDay
            }
          }
        }`;
      } else {
        // on ajoute eachHour
        getMeasureBetween = `query getMeasureFromDay($id: ID!, $startDay: String, $endDay:String, $itemsLimit: Int) {
          listMeasureDailyStatsLocals(id: $id, dayEvent: {between: [ $startDay, $endDay ]},
          sortDirection: ASC,
          limit: $itemsLimit) {
            items {
              id
              dayEvent
              allDay
              eachHour
            }
          }
        }`;
      }

      const response = await GraphQlApi.getInstance().graphqlOperation( getMeasureBetween, inputData);
      if (response.errors) {
        console.log("response:",response.errors)
      }
      var measures;
      if (response.data) {
        console.log("response:",response.data)
        measures = response.data.listMeasureDailyStatsLocals.items;
      }
      measures = response.data.listMeasureDailyStatsLocals.items;
      console.log("data loaded size:",measures.length)


      if (measures.length === QUERY_MEASURES_LIMIT) {
        //toSnack("MeasuresLimit","Le nombre maximum de mesures est atteint. Il est possible que toutes les informations ne soient pas présentes ("+QUERY_MEASURES_LIMIT+")");
        setDisplayWarningMeasuresLimit(true);
      }
      

      if (measures.length===0) {
        console.log("no data");
        return;
      }

      var values = [];
      //TODO if allday === null ?
      const firstMeasureDayAllDayStats = JSON.parse(measures[0].allDay);
      const stats = {
        min: firstMeasureDayAllDayStats.min,
        max: firstMeasureDayAllDayStats.max, 
        sum: 0
      } 
      // chaque enreg contient les (au max) les 24h du jour
      // on reconstitue un Array linéaire
      for( const dayStats of measures) {
        const statsDay = dayStats.dayEvent;

        const allDay = JSON.parse(dayStats.allDay);

        if (!isStatsOnly) {
          // on ajoute les data dans la réponse
          // on extrait les heures de chaque jour
          if (dataByDayOrHour==='hour') {
            const eachHour = JSON.parse(dayStats.eachHour);
            // on parcours les heures du jour pour collecter les valeurs (moyenne horaire ou somme)
            for (let h = 0; h < 24; h++) {
                if (eachHour.hasOwnProperty("h"+h)) {
                  //const measureTime = moment(statsDay+" "+h, 'YYYY-MM-DD k').utc().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'); // .toISOString() //.toDate();
                  const measureTime = moment(statsDay+" "+h, 'YYYY-MM-DD k').toISOString(); // on rammene en GMT pour ajouter le décalage de l'utilisateur sur le graph
                  // on retourne un moyenne horaire ou une somme pour cette heure
                  const theValue = (sumOrAvg==='avg')?eachHour["h"+h].avg : eachHour["h"+h].sum;
                  values.push({ timeEvent: measureTime, value: theValue})
                }
            }
          } else {
            // on renvoi uniquement les sum par jour (pour des cumul/graph histo par jour)
            const measureTime = moment(statsDay, 'YYYY-MM-DD').toISOString(); // on rammene en GMT pour ajouter le décalage de l'utilisateur sur le graph
            values.push({ timeEvent: measureTime, value: allDay.sum})
          }

        }
        // stats ....
        // dans allDay
        // exploiter les min,max
        // cumuler la sum
        if (allDay) {
          // on cumul la somme des cumuls journalier de la période
          stats.sum = stats.sum + allDay.sum;

          if (allDay.max>stats.max)  {
            stats.max = allDay.max;
          }
          if (allDay.min < stats.min)  {
            stats.min = allDay.min;
          }
        }
      }
      if (stats) {
        stats['sum'] = round(stats['sum'],2)
      }
      dataresponse = {
        values: values,
        stats: stats
      }
      return dataresponse;
    }

    function computeSumForAvg(values) {
      return values.reduce(function (acc, obj) {
        let dayAvg = obj['value'];
        return acc + dayAvg;
      }, 0);
    }


    async function getHistoAggregateOneDay(serviceId, probeId, periodStats) {
      console.log("getHistoAggregateOneDay");
      console.log("periodStats: ",periodStats);

      var measure;

      const idKey = serviceId+"-"+probeId;
      const theDay = moment(periodStats).format('YYYY-MM-DD');
      const inputData = { "id": idKey, "theDay": theDay}

      console.log("inputData:",inputData)
      const getMeasure = `query getMeasureFromDay($id: ID!, $theDay: String) {
        listMeasureDailyStatsLocals(id: $id, dayEvent: {eq: $theDay}) {
          items {
            id
            allDay
          }
        }
      }`;
      const response = await GraphQlApi.getInstance().graphqlOperation( getMeasure, inputData);
      if (response.errors) {
        console.log("response:",response.errors)
      }
      if (response.data) {
        console.log("response:",response.data)
        const measures = response.data.listMeasureDailyStatsLocals.items;
        if (measures.length>0) {
          measure = measures[0];
        }
      }
      return measure;
    }

  function getDefaultPeriod(service, probeId) {
    var defaultPeriod;

    const probesDisplay = JSON.parse(service.device.template.probesDisplay);

    if (probesDisplay && probesDisplay[probeId] && probesDisplay[probeId].defaultPeriod) {
      defaultPeriod = probesDisplay[probeId].defaultPeriod;

    } else {
      defaultPeriod = "today"
    }
    return defaultPeriod;
  }
  
  // formatage des datas pour le graph (avec des données aggrégés à l'heure)
  // pas de probe.type = "STATE_IN"
  function dataForGraphOneProbe(rawData, service, probeId, nowInsideRange, graphType) {
    //console.log("rawData:",rawData);

    // on recupere  le détail de la probe demandée (Type, nom) issue du service
    const probeConfig = getProbeDetail(service, probeId);
    if (!probeConfig) {
      console.log("Attention : information sur la sonde non trouvée dans le service")
    }

    var dataSerie = { 
      name: probeConfig.name, 
      type : probeConfig.type, 
      unit : probeConfig.unit, 
      data : [] 
    }   
    // Graph prédifini dans le modele ou peut-être par l'utilisateur        
    if (probeConfig.graph) {
      dataSerie["graphCfg"] = probeConfig.graph;
    }
    dataSerie["show"] = 'left';
    dataSerie["graphCfg"] = '';
    dataSerie["graphType"] = graphType;
  
    rawData.map( item => {
      var probeValue = item.value;
      // type de probe autre que E/S, on l'ajoute normalement
      dataSerie.data.push({ x: item.timeEvent, y:probeValue })
    })

    var byProbes = {};
    byProbes[probeId] = dataSerie;

    //console.log("byProbes (sorted):",byProbes)
    return byProbes;     
  }

  // formatage des datas pour le graph
  // possibilité d'envoyer plusieurs serie de même type (1 cseul axe Y)
  ///TODO Traitement très similaire dans ServiceGraphPage.dataByProbeArray(rawData, service) a factoriser
  function dataForGraph(rawData, service, nowInsideRange, graphType) {
    //console.log("rawData:",rawData);

    // on recupere  le détail de la probe demandée (Type, nom) issue du service
    const probeConfig = getProbeDetail(service, probeId);
    if (!probeConfig) {
      console.log("Attention : information sur la sonde non trouvée dans le service")
    }

    // si probeId = show = yes
    // suppression des sondes de type differents que type probe
    var byProbes = {};
    rawData.map( item => {
        const probes = JSON.parse(item.values);
        for(const probeKey in probes) {
          const probe = probes[probeKey];

          const detail = getProbeDetail(service, probeKey);
          var probeValue = probe.value;

          //pour les sondes de type Entrée binnaire
          if (probe.type==="STATE_IN") {
            probeValue = probe.state;
/*             
            // Pour une inversion uniquement à l'affichage
            // actuellement l'inversion est lors du décodage
            if (detail.hasOwnProperty("stateOn") && detail.stateOn===0) {
              console.log("Inversion des etats")
              probeValue = (probeValue===0)?1:0;
            }
 */            
          }

          // est que byProbes[probeKey] existe déja ?
          var probeData = byProbes[probeKey]
          if (!probeData) {
            // creation

            // get depuis in template  by probe

            var dataSerie = { 
              name: detail.name, 
              type : detail.type, 
              unit : detail.unit, //{/* EVOL1 */}
              //data : [{ x: new Date(probe.timeEvent), y:probe.value }] 
              data : [{ x: probe.timeEvent, y:probeValue }] 
            }

            if (probe.type==="STATE_IN") {
              dataSerie["graphType"] = "SteppedLine"; // uniquement pour activer ls lignes droite non extrapolée
            } else {
              dataSerie["graphType"] = graphType
            }         
            
            if (detail.graph) {
              dataSerie["graphCfg"] = detail.graph;
            }
            //TODO ajouter les annotations

            // on affiche par defaut, la probe demandée
            // mais on ajoute d'autre probe du même type

            // si la sonde est du mêm type que la sonde demandée (ex temperature), 
            // on l'ajoute dans le graphique au cas ou (même axe)
            if (probeConfig.type === detail.type) {
              //on affiche par défait la sonde demandée
              if (probeKey === probeId ) {
                dataSerie["show"] = 'left';
              }
              byProbes[probeKey] = dataSerie;
            }

          } else {
            // si E/S : on ajoute un event pour faire une courbe en escalier
            if (probe.type==="STATE_IN") {
              if(probeValue===1) {
                byProbes[probeKey].data.push({ x: probe.timeEvent, y:0 });
                byProbes[probeKey].data.push({ x: probe.timeEvent, y:probeValue });
              } else {
                //0
                byProbes[probeKey].data.push({ x: probe.timeEvent, y:1 });
                byProbes[probeKey].data.push({ x: probe.timeEvent, y:probeValue });
              }

            } else {
              // type de probe autre que E/S, on l'ajoute normalement
              byProbes[probeKey].data.push({ x: probe.timeEvent, y:probeValue })
            }
          }
          //
        }
    })

    console.log("byProbes for Graph avant tri:",byProbes);
    // dans certain cas l'ordre temporel des valeurs de la probe n'est pas respecté
    // Pour être plus sur que l'affichage se fasse dans l'ordre temporel,
    // on tri sonde par sonde (pas très bon au niveau perf !)
    sortingEveryProbeData(byProbes)

    if (nowInsideRange) {
      //Si courbe on/off, injecter une mesure, à la fin, qui prolonge le dernier etat jusqu'au moment de l'observation
      // pour chaque probe
      for(const key in byProbes) {
        if (byProbes[key].type==="STATE_IN") {
          const allData = byProbes[key].data;
          if (allData.length>0) {
            const lastItem = allData[allData.length-1]
            console.log("lastItem:",lastItem);
            const probeLastValue = lastItem.y;
            // inserrer une mesure pour prolonger le dernier etat jusqu'au moment où l'on regarde
            const now = new Date().toUTCString();
            byProbes[key].data.push({ x: now, y:probeLastValue })
            console.log("Insertion du mesure fictive pour prolonger le dernier etat jusqu'au moment de l'observation")
          }
        }
      } 
    }
    console.log("byProbes for Graph (sorted, et retouche on/off):",byProbes)
    return byProbes;     
  }
  function dataAggregateForCSV(rawData) {
    var csvData = [];
    //console.log("dataAggregateForCSV:",rawData)
    rawData.map( item => {
      var newLine = {};
      newLine["date"] = moment(item.timeEvent).format("DD/MM/YYYY HH:mm:ss");
      newLine["valeur"] =  String(item.value).replace('.',',');
      csvData.push(newLine)
    })
    //console.log("csvData:",csvData)
    return csvData;     
  }

  function dataForCSV(rawData) {
    var csvData = [];
    var addRawValue = false;
    if (customer.isUserAdmin) addRawValue= true;
    rawData.map( item => {
      const probes = JSON.parse(item.values);
      if (probes[probeId]) {
        const probe = probes[probeId];
        var newLine = {};
        newLine["date"] = moment(probe.timeEvent).format("DD/MM/YYYY HH:mm:ss");
        newLine["valeur"] =  String(probe.value).replace('.',',');
        if (probe.more && probe.more.rawValue && addRawValue) {
          newLine["valeur_brute"] = String(probe.more.rawValue).replace('.',','); // valeur avant application du ax+b sur la ptf
        }
        csvData.push(newLine)
      }
    })
    //console.log("csvData:",csvData)
    return csvData;     
  }

  function sortingEveryProbeData(byProbes) {
    for(const key in byProbes) {
      byProbes[key].data.sort((a, b) => a.x - b.x);
    }
  }

  // callback du datePicker suite action utilisateur
  async function newDateRangeReceived(rangeType, start, end) {
    console.log("Received histo rangeType :",rangeType, start, end);

    // si la probe est de type sum/cumul
    // decalage d'une ms pour ne pas prendre l'event de tout début 00h00:00:00
    // qui correspond à un cumul pour la veille
    var newStart = moment(start);
    var newEnd = moment(end);
    if (probeConfig.isSum) {
      console.log("Sonde de type cumul, on décale de 1ms (Linky)")
      newStart.add(1, 'ms');
      newEnd.add(1, 'ms');
    }

    setHistoRangeType(rangeType);
    setHistoDateRange({start: newStart.toDate(), end: newEnd.toDate()})
  }



  const isInsideDateRange = (point, start, end)  => {
    const thePoint = new Date(point);
    return (thePoint > new Date(start) && thePoint <= new Date(end))?true:false;
  }

  function generateFileName(service, probeId, histoDateRange) {
    // le nom du service sans blanc sur 10
    const name = (service.name).substring(0,10).replace(' ','_');
    // la période
    const periode = moment(histoDateRange.start).format("YYYY-MM-DD_HH")+"-"+moment(histoDateRange.end).format("YYYY-MM-DD_HH")
    // le deveui
    const deveui = service.device.id
    //return "data_export_allProbes.csv"
    return name+"_"+periode+"_"+probeId+"_"+deveui+".csv"
  }
  
  const yAxisSwitchCallBack = (value) => {
    console.log("value:",value)
    var yAxixBeginAtZero;
    if (value==="zero") {
      yAxixBeginAtZero = true;
    } else {
      // relative
      yAxixBeginAtZero = false;
    }
    setChartOptions((prevState) => {
      return { ...prevState, ...{'beginAtZero':yAxixBeginAtZero}};
   });
  }
  const avgOrSumSwitchCallBack = (value) => {
    console.log("value:",value)
    //setSumOrAvg(value); // pour le calcul des data
    setChartOptions((prevState) => {
      return { ...prevState, ...{'sumOrAvg':value}};
   });
  }  
  const hourOrDaySwitchCallBack = (value) => {
    console.log("value:",value)
    setViewByHourOrDay(value); // hour / day
    setChartOptions((prevState) => {
      return { ...prevState, ...{'hourOrDay':value}};
   });
  } 

  function onChartClickCallback(dateClicked) {
    console.log("dateClicked:",dateClicked)
    if (displayPeriod>1) {
      // on affiche plusieurs jours, c'est possible de 'zoomer' sur un jour
      const dayClicked = moment(dateClicked).format('YYYY-MM-DD');
      console.log("dayClicked:",dayClicked)
      const histoDateRange = {
        start: moment(dayClicked).startOf('day').toDate(),
        end: moment(dayClicked).endOf('day').toDate()
      }
      //console.log("histoDateRange:",histoDateRange)
      //setHistoDateRange(histoDateRange);

      const startPeriod = moment(dayClicked).startOf('day').toString();
      const rangeType = "oneDay";
      console.log("histoDateRange start:%s range type:%s",startPeriod, rangeType)
      //setDefaultStartPeriodPicker(startPeriod)
      //setHistoRangeType(rangeType);
      setDefaultRangePicker({type: "oneDay", start: startPeriod})
      // le datePicker doit faire le changment de periode et declancher la recherche de data pour la periode
   }
  }

  const formatNumber = (rawValue, decimal) => {
    if (rawValue<10) {
      return String(round(rawValue, 2));
    } else {
      // sans décimale avec 1 blanc tous les 1000
      return String(round(rawValue, decimal)).replace(/(.)(?=(\d{3})+$)/g,'$1 ')
    }
  }

  const displayAddingPeriod = (value) => {
    var toDisplay;
    if (value==="day") {
      toDisplay = "jour";
    } else if (value==="hour") {
      toDisplay = "heure";
    } else {
      toDisplay = "?";
    }
    return toDisplay;
  }
  
    return (
      <div>
        <AppBar className={classes.appBarParam}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
              <BackIcon />
            </IconButton>
            <Typography variant="h6" className={classes.titleParam}>
            {service.name}
            </Typography>
          </Toolbar>
        </AppBar>        

        <div className={classes.alignItemsAndJustifyContent}>
          {loading && <CircularProgress size={40} />}
        </div>   

        <div>

          <MyDateRangePicker  onChange={newDateRangeReceived} defaultRange={defaultRangePicker} /* defaultRangeType={histoRangeType} defaultStartPeriod={defaultStartPeriodPicker} *//>



        </div>
        {histoData && 
/*           <GraphHisto data={histoData} probeId={probeId}  config={graphConfig}/> */
          <>
            <ChartMonoAxe dataSets={histoData} config={chartConfig} userOptions={chartOptions} onClickCallback={onChartClickCallback} />

          { periodStats &&
            <>
              <Grid container direction="column"   className={classes.bottomOptions}>
{/*                 <Grid item>
                  Sur la période
                </Grid> */}
               
                <Grid item>
                  <Grid container direction="row"   className={classes.bottomOptions}>
                    <Grid xs={2} item>
                    {probeConfig.unit === 'W/m2' &&
                      <div>
                        <div className={classes.statsHeader}>Total période</div>
                        <div>{formatNumber(periodStats.sum/1000,2)} kWh/m2</div>
                        <div>{formatNumber(periodStats.sum*3600/1000)} kJ/m2</div>
                      </div>
                    }
                    {probeConfig.unit !== 'W/m2' &&
                      <div>
                        <div className={classes.statsHeader}>Total période</div>
                        <div>{formatNumber(periodStats.sum)} {probeConfig.unit}</div>
                      </div>
                    }                    
                    
                    </Grid>                     
                    <Grid xs={3} item>
                      <div className={classes.statsHeader}> Mesure minimum</div>
                      <div>{formatNumber(periodStats.min)} {probeConfig.unit}</div>
                    </Grid>
                    <Grid xs={3} item>
                      <div className={classes.statsHeader}> Mesure maximum</div>
                      <div>{formatNumber(periodStats.max)} {probeConfig.unit}</div>
                    </Grid>
                  </Grid>
                </Grid> 
                <Grid item>
                  <Grid container direction="row"   className={classes.bottomOptions}>
                    <Grid xs={12} item>
                    { displayPeriod > DAYS_FOR_AGGREG_DATA &&
                    <>
                      <div>
                        {probeConfig.isSum &&
                          <div>
                           (Chaque point est cumul par {displayAddingPeriod(viewByHourOrDay)})
                          </div>
                        }
                        </div>
                        <div>
                        {!probeConfig.isSum &&
                          <div>(Chaque point est une moyenne des mesures par heure)</div>
                        }
                      </div>
                    </>
                    }
                    { displayPeriod <= DAYS_FOR_AGGREG_DATA &&
                    <>
                      <div>
                        {probeConfig.isSum &&
                          <div>(Chaque point est un cumul de mesures / à la mesure précédente)</div>
                        }
                      </div>
                      <div>
                        {!probeConfig.isSum &&
                          <div>(Chaque point est une mesure)</div>
                        }
                      </div>                      
                    </>
                    }
                    </Grid>                     
                  </Grid>
                </Grid>                  
              </Grid>
            </>
          }
            <Grid container direction="row"  alignItems="flex-end" justifyContent="flex-end" className={classes.bottomOptions}>
              <Grid  xs={4} item>
                <YAxisSwitch  callBack={yAxisSwitchCallBack}/>     
              </Grid>
               <Grid  xs={4} item>
               { displayPeriod>DAYS_FOR_AGGREG_DATA && probeConfig.isSum &&
                <HourOrDaySwitch  callBack={hourOrDaySwitchCallBack} defaultValue ="hour"/>    
               }
              </Grid>              
              <Grid  xs={4} item>
                      <Grid container  justifyContent="center" direction="column" alignItems="center">
                        <Grid item>
                          <CSVLink 
                            style={ {
                              paddingRight: '40px',
                              border: 'none',
                              color: 'inherit',
                              padding: '0px',
                              font: 'inherit',
                              outline: 'inherit',
                              zIndex: 'inherit',
                              boxShadow: 'none'
                            } }                 
                            filename={csvFileName} 
                            separator={";"}
                            data={csvData}>
                                <CloudDownloadIcon fontSize="large" />
                          </CSVLink>
                        </Grid>
                        <Grid item>
                          Export CSV
                        </Grid>
                      </Grid>
               
              </Grid>
            </Grid>

            {displayWarningMeasuresLimit &&
              <div className={classes.limitWarning}>
                <Grid container  direction="column"  justifyContent="center"   alignItems="center">
                  <Grid item>Attention</Grid>
                  <Grid item>Le nombre maximum de mesures récupérées pour la période est atteint ({QUERY_MEASURES_LIMIT}). </Grid>
                  <Grid item>Il est possible que toutes les informations ne soient pas présentes.</Grid>
                  <Grid item>Vous pouvez reduire la période de temps pour que le nombre de mesures soit inférieur à 1000.</Grid>
                </Grid>  
              </div>              
            } 
          </>
        }
      </div>

  );
}

export function YAxisSwitch({callBack}) {
  const [value, setValue] = React.useState('zero');

  const handleChange = (event) => {
    setValue(event.target.value);
    callBack(event.target.value);
  };

  const classes = useStyles();

  return (

    <FormControl className={classes.yAxisRadioGroup}>
      <FormLabel >Axe Y</FormLabel>
      <RadioGroup row aria-label="yAxe" name="yAxe" value={value} onChange={handleChange}>
        <FormControlLabel value="zero"     control={<Radio color="primary" />} label="0"        />
        <FormControlLabel value="relative" control={<Radio color="primary" />} label="relatif"   />
      </RadioGroup>
    </FormControl>
  );
}

export function AvgOrSumSwitch({callBack, defaultValue}) {
  const [value, setValue] = React.useState(defaultValue);

  const handleChange = (event) => {
    setValue(event.target.value);
    callBack(event.target.value);
  };

  const classes = useStyles();

  return (

    <FormControl className={classes.yAxisRadioGroup}>
      <FormLabel >Moy/Somme</FormLabel>
      <RadioGroup row aria-label="avgOrSum" name="avgOrSum" value={value} onChange={handleChange}>
        <FormControlLabel value="avg" control={<Radio color="primary" />} label="Moyenne"        />
        <FormControlLabel value="sum" control={<Radio color="primary" />} label="somme"   />
      </RadioGroup>
    </FormControl>
  );
}

export function HourOrDaySwitch({callBack, defaultValue}) {
  const [value, setValue] = React.useState(defaultValue);

  const handleChange = (event) => {
    setValue(event.target.value);
    callBack(event.target.value);
  };

  const classes = useStyles();

  return (

    <FormControl className={classes.yAxisRadioGroup}>
      <FormLabel >Cumul par Heure/Jour</FormLabel>
      <RadioGroup row aria-label="hourOrDay" name="hourOrDay" value={value} onChange={handleChange}>
        <FormControlLabel value="hour" control={<Radio color="primary" />} label="Heure"        />
        <FormControlLabel value="day" control={<Radio color="primary" />} label="Jour"   />
      </RadioGroup>
    </FormControl>
  );
}